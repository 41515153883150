import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReasignarSlotDto, VentanaSolcitudContratoConfig } from '../models/ventana-config.model';
import { ResponseBackAzure } from '@core/models/responsehttp.model';

@Injectable({
  providedIn: 'root',
})
export class VentanaSolicitudContratoService {
  protected _http = inject(HttpClient);
  private readonly httpParam = new HttpParams().append('backend', 'azure');

  private readonly urlBase = 'contratos/ventas/ventana';

  postConfiguracion(body: VentanaSolcitudContratoConfig) {
    return this._http.post<any>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/contrato`, body, {
      params: this.httpParam,
    });
  }

  getConfiguracion() {
    return this._http.get<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/contrato`, {
      params: this.httpParam,
    });
  }

  putConfiguracion(body: Partial<VentanaSolcitudContratoConfig>) {
    return this._http.put<any>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/contrato`, body, {
      params: this.httpParam,
    });
  }

  deleteConfiguracion(id: string) {
    return this._http.delete<any>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/contrato/${id}`, {
      params: this.httpParam,
    });
  }

  getVentanasData(fecha_inicio: string, diasAdelante: number) {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/slot?origen=SOLICITUD_CONTRATO&fecha_inicio=${fecha_inicio}&dias_adelante=${diasAdelante}`,
      {
        params: this.httpParam,
      }
    );
  }

  asignarSlot(id_contrato: string) {
    return this._http.post<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/contrato/slot`,
      { id_contrato },
      {
        params: this.httpParam,
      }
    );
  }

  reassignSlot(body: ReasignarSlotDto) {
    return this._http.put<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/slot`, body, {
      params: this.httpParam,
    });
  }

  getTablaControl() {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/tabla-control?origen=SOLICITUD_CONTRATO`,
      {
        params: this.httpParam,
      }
    );
  }

  getDataAdminVentanas(fecha_inicio: string, diasAdelante: number) {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/slot/detalle?origen=SOLICITUD_CONTRATO&fecha_inicio=${fecha_inicio}&dias_adelante=${diasAdelante}`,
      {
        params: this.httpParam,
      }
    );
  }
}
